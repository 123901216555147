@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-display: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-display: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: medium;
  font-style: normal;
}
@font-face {
  font-display: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-display: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1, h2, h3, h4, h5, h6, p, input {
  font-display: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

h1 {
  font-size: 20px;
}
h2 {
  font-size: 16px;
}
h3 {
  font-size: 14px;
}
h4 {
  font-size: 12px;
}
h5 {
  font-size: 28px; /* Titulo pagina veículo */
}
h6 {
  font-size: 35px; /* Titulo dos formulários */
}
p {
  font-size: 18px;
}
@media (max-width: 991px) {
  h1 {
    font-size: 16px;
  }
  h2 {
    font-size: 14px;
  }
  h3 {
    font-size: 12px;
  }
  h6 {
    font-size: 16px; /* Titulo dos formulários */
  }
  input, input::placeholder, textarea, textarea::placeholder {
    font-size: 12px;
  }
}

.select-radius {
  border-radius: 100px;
  font-display: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

select {
  font-display: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

input, input::placeholder, textarea, textarea::placeholder {
  font-display: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  font-family: Montserrat;
  font-style: normal;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: normal;
}
